import type { QueryResult } from '@apollo/client';
import { useContext } from 'react';
import type {
  NodePoliciesQuery,
  NodePoliciesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import AppContext from '../context/AppContext/AppContext';
import useNodePolicies from '../nodes/useNodePolicies';

/**
 * Gets policies for the community. This is a thin wrapper around `useNodePolicies`
 * that specifies the community id as the node id.
 *
 * @param module The module
 * @param variables any node policies query variables.
 * @param skip whether to skip the graphql call.
 * @param ssr whether to run the query on the server side render
 *
 * @author Adam Ayres
 */
export default function useCommunityPolicies(
  module: NodeModule | string,
  variables: Omit<NodePoliciesQueryVariables, 'id'>,
  skip = false,
  ssr = true
): QueryResult<NodePoliciesQuery, NodePoliciesQueryVariables> {
  const { community } = useContext(AppContext);
  const modifiedVariables = { id: community.id, ...variables };
  return useNodePolicies(module, modifiedVariables, skip, ssr);
}
